import { profile } from "console";

import { CropFree, Delete } from "@mui/icons-material";
import { Fab, Popover } from "@mui/material";
import { Button } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { toast } from "react-toastify";

import { useAppSelector } from "src/hooks";

import { updateImages } from "src/api/backend-endpoints";
import defaultProjectImage from "src/assets/images/defaultProjectImage.png";
import { ApiAxiosClient } from "src/axios";
import useImageLoading from "src/hooks/useImageLoading";
import SolarLayout from "src/shared/components/SolarLayout/SolarLayout";
import rolechecker from "src/shared/RoleChecker/rolechecker";
import { isQuoteNotVerified, isQuotePending, isQuotePresent, isValidURL } from "src/utils";

import * as S from "../../../../styles";

import { ImageSlider } from "./components";

import { ProjectActions } from "src/redux/actionCreators";
import { openPopup } from "src/redux/popups/action/action.creators";
import { getAddress } from "src/redux/project/selectors";
import { ProjectSelectors } from "src/redux/selectors";
import { getSolarAPIData } from "src/redux/solarAPI/selector";
import { getInstaller, getUser } from "src/redux/user/selectors";



import { POPUP_KEYS } from "src/redux/popups";

const IMG_SATTELITE_IND = 0;
interface ImageBoxProps {
  is3DView: boolean;
  setFluxMonth: (key: number) => void;
  fluxMonth: number;
  selectedOption: string;
}
const ImageBox: React.FC<ImageBoxProps> = ({ fluxMonth, setFluxMonth, selectedOption }) => {
  const quoteProfile = useSelector(ProjectSelectors.getQuoteProfile);
  const address = useSelector(getAddress);
  const project = useAppSelector(ProjectSelectors.getProject);
  const coords = useSelector(ProjectSelectors.getAddressCoords);
  const quoteId = useSelector(ProjectSelectors.getQuoteId);
  const status = useSelector(ProjectSelectors.getQuoteStatus);
  const installerPartner = useSelector(getInstaller);
  const solarAPIData = useSelector(getSolarAPIData);
  const selectedInstallers = useSelector(ProjectSelectors.getSelectedInstaller);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isZoomedViewOpen, setIsZoomedViewOpen] = useState(false);
  const [currImageInd, setCurrImageInd] = useState(IMG_SATTELITE_IND);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [imageToDelete, setImageToDelete] = useState<{ imageUrl: string; index: number } | null>(
    null,
  );
  const user = useSelector(getUser);
  const imageRef = useRef<HTMLImageElement>(null);
  useImageLoading(imageRef);

  const installerImages = useMemo(() => {
    return (
      selectedInstallers[0]?.files?.filter(
        (img) => !img.includes(".obj") && !img.includes(".mtl"),
      ) || []
    );
  }, [selectedInstallers]);

  const filteredImages = useMemo(() => {
    if (installerImages.length > 0) return installerImages;
    return quoteProfile?.imgURLs.filter((img) => {
      const fileExtension: any = img?.split(".").pop()?.toLowerCase();
      const isInvalidExtension = ["obj", "mtl", "pvprj"].includes(fileExtension);
      if (isInvalidExtension) {
        return false;
      }
      if (img.includes("Destination") && isValidURL(img)) {
        if (address.city === "Gundelfingen") {
          return !img.includes("Baked") && isValidURL(img);
        } else {
          return img.includes("Screenshot");
        }
      }
      return isValidURL(img);
    });
  }, [address, quoteProfile?.imgURLs, installerImages]);

  useEffect(() => {
    setCurrImageInd(0);
  }, [quoteProfile]);

  const sliderSettings = useMemo(() => {
    return {
      accessibility: true,
      speed: 600,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      nextArrow: (
        <div
          id="next-btn"
          onClick={(e) => {
            console.log("Called");
            e.stopPropagation();
          }}
        />
      ),
      prevArrow: (
        <div
          id="prev-btn"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      ),
    };
  }, []);

  const handleDeleteImage = async (url: string) => {
    try {
      const updatedImageURLs = project.quote.profiles.green.imgURLs.filter((_, i) => _ !== url);
      await ApiAxiosClient.put(updateImages.url, {
        imgUrls: updatedImageURLs,
        profile: project.profile,
        id: project?.quoteId,
      });
      toast.success(t("Image Deleted Successfully"));
      dispatch(
        ProjectActions.updateProject({
          ...project,
          quote: {
            ...project.quote,
            profiles: {
              ...project.quote.profiles,
              green: {
                ...project.quote.profiles.green,
                imgURLs: updatedImageURLs,
              },
            },
          },
        }),
      );
    } catch (e) {
      console.error("An error occurred:", e);
      toast.error(t("Something gone wrong"));
    }
  };

  const handleOpenConfirm = (event: any, url: string, index: number) => {
    setAnchorEl(event.currentTarget);
    setImageToDelete({ imageUrl: url, index });
  };

  const handleCloseConfirm = () => {
    setAnchorEl(null);
    setImageToDelete(null);
  };

  const handleConfirmDelete = async () => {
    console.log(imageToDelete, "imageToDelete");
    if (imageToDelete !== null) {
      await handleDeleteImage(imageToDelete.imageUrl);
      handleCloseConfirm();
    }
  };

  const open = Boolean(anchorEl);
  const isToShowSolarLayout = useMemo(
    () => !project.googleModel && isQuotePresent(quoteId) && isQuoteNotVerified(status),
    [isQuoteNotVerified, isQuotePresent, quoteId, status, isQuotePending],
  );
  const isRoleValid = rolechecker(user?.role);
  const isthreedmodel = project?.quote?.profiles?.green?.imgURLs.filter((img) => {
    const fileExtension = img.split(".").pop()?.toLowerCase();
    return ["obj", "mtl"].includes(fileExtension as string);
  });
  
  return (
    <>
      <S.ImageContainer>
        {isToShowSolarLayout && installerImages.length === 0 && (
          <SolarLayout
            selectedOption={selectedOption}
            setFluxMonth={setFluxMonth}
            fluxMonth={fluxMonth}
            height={250}
            width={350}
            center={{ lat: coords[1], lng: coords[0] }}
          />
        )}
        <>
          {(!isToShowSolarLayout || installerImages.length > 0) && (
            <Slider {...sliderSettings}>
              {filteredImages?.length > 0 ? (
                filteredImages.map((url: any, ind: number) => {
                  return (
                    <div key={url} className="image_section">
                      <img src={url} alt="" crossOrigin="anonymous" />
                      {isRoleValid && (
                        <div className="delete-image-button">
                          <Fab
                            aria-label="delete"
                            style={{
                              backgroundColor: "var(--primary-color)",
                              height: "30px",
                              width: "36px",
                            }}
                            onClick={(event) => handleOpenConfirm(event, url, ind + 1)}
                          >
                            <Delete style={{ color: "#2d4764" }} />
                          </Fab>
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <img src={defaultProjectImage} alt="house" />
              )}
            </Slider>
          )}
          {!solarAPIData && (
            <div className="view-image-button">
              <Fab
                aria-label="cropFree"
                style={{ backgroundColor: "var(--primary-color)", height: "30px", width: "36px" }}
                onClick={() => setIsZoomedViewOpen(true)}
              >
                <CropFree style={{ color: "#2d4764" }} />
              </Fab>
            </div>
          )}
          {isthreedmodel.length > 0 && (
            <div
              className="view-3d-button"
              style={{ color: "var(--primary-color)", fontSize: "large", fontWeight: "900", marginLeft:"2px" }}
              onClick={() => {
                dispatch(openPopup(POPUP_KEYS.threedmodel, { isfromSavedProject: false }));
              }}
            >
              3D
            </div>
          )}
        </>
      </S.ImageContainer>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseConfirm}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          style={{
            background: "white",
            border: "2px solid white",
            borderRadius: "5px",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <div>{t("Are you sure you want to delete this image?")}</div>
          <div
            style={{ display: "flex", alignItems: "center", gap: "10px", justifyContent: "right" }}
          >
            <Button size="small" color="error" onClick={handleCloseConfirm}>
              {t("Cancel")}
            </Button>
            <Button size="small" color="success" onClick={handleConfirmDelete}>
              {t("Confirm")}
            </Button>
          </div>
        </div>
      </Popover>
      {isZoomedViewOpen && (
        <ImageSlider setOpen={setIsZoomedViewOpen} currImageInd={currImageInd} />
      )}
    </>
  );
};

export default ImageBox;
