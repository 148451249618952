import * as THREE from "three";
import { MeshoptDecoder } from "three/addons/libs/meshopt_decoder.module.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { KTX2Loader } from "three/addons/loaders/KTX2Loader.js";
import { MTLLoader } from "three/addons/loaders/MTLLoader.js";
import { OBJLoader } from "three/addons/loaders/OBJLoader.js";


import { MeshpotPanel,deleteIcon } from "src/assets/step33dViwer";

const resourcePath = "https://solarhub-medias-staging.s3.us-east-1.amazonaws.com/products/6695142e0a9684f59079b41e/";

const loadIcon = async (url) => {
    const tex = await new THREE.TextureLoader().loadAsync(
        url || deleteIcon
    );
    tex.colorSpace = "srgb";
    const deleteObject = new THREE.Sprite(
        new THREE.SpriteMaterial({
            map: tex,
            // sizeAttenuation:false,
        })
    );
    // deleteObject.scale.multiplyScalar(.1)
    return deleteObject;
};

const initLoaders = () => {
    const mtlLoader = new MTLLoader();
    const objLoader = new OBJLoader();
    mtlLoader.setResourcePath(
        resourcePath
    );

    return {
        mtlLoader,
        objLoader
    };
};

const loadMaterials = async (mtlLoader,mtlUrl) => {
    const materials = await mtlLoader.loadAsync(
        mtlUrl || "https://solarhub-medias-staging.s3.us-east-1.amazonaws.com/products/6694369e6b588eee674751c0/Solarmodul_1722x1134x30.mtl"
    );

    materials.preload();
    return materials;
};

  const loadOBJPanelModule = async (url,materials,dimentions) => {
    const objLoader = new OBJLoader();
    objLoader.setMaterials(materials);
    const obj = await objLoader.loadAsync(
        url || "https://solarhub-medias-staging.s3.us-east-1.amazonaws.com/products/6694369e6b588eee674751c2/Solarmodul_1722x1134x30.obj"
    );

    // Set size
    if(dimentions) {
        const boundingBox = new THREE.Box3().setFromObject(obj);
        const currentSize = new THREE.Vector3();
        boundingBox.getSize(currentSize)

        // Calculate scale factors
        const scaleFactors = {
            x: dimentions.height / currentSize.x,
            y: dimentions.width / currentSize.y
        };

        // Apply the scaling
        obj.scale.set(scaleFactors.x, scaleFactors.y);
    }

    // Traverse through the object's meshes and assign layers
    obj.traverse((child) => {
        if (child.isMesh) {
          // Set this mesh to layers 1 and 2 (for example)
          child.layers.enable(0);  // Enable layer 1
          child.layers.enable(1);  // Enable layer 2
        }
    });
    obj.rotateY(Math.PI);

 obj.traverse((child) => {
    if (child.isMesh) {
        // Add reflective material properties
        const loader = new THREE.TextureLoader();
        let texturePath;
        if(child.material.map) {
            texturePath = child.material.map.sourceFile || child.material.map.image.src;
        }else {
            texturePath = resourcePath + child.material.name + ".png";
        }
        loader.load(texturePath, function(texture) {
            child.material = new THREE.MeshStandardMaterial({
                map: texture,
                metalness: 1,    
                roughness: 0.7,
                envMapIntensity: 1, 
            });
            // Ensure the material updates
            child.material.needsUpdate = true;
        });
        child.castShadow = true;   
        child.receiveShadow = true; 
    }
});

    return obj;
};

const loadGLTFModule = async (renderer, url) => {
    const glbLoader = new GLTFLoader();
    const ktx2Loader = new KTX2Loader()
        .setTranscoderPath(
            "https://threejs.org/examples/jsm/libs/basis/"
        )
     .detectSupport(renderer);
    glbLoader.setKTX2Loader(ktx2Loader);
    glbLoader.setMeshoptDecoder(MeshoptDecoder);



    // const panel = await glbLoader.loadAsync(
    //     url || MeshpotPanel
    // );
    // console.log(panel);
    
    // panel.scene.scale.multiplyScalar(0.15);
    // panel.scene.rotation.x = Math.PI * 0.5;

    // Set all color channels to white
    // panel.scene.traverse((e) => {
    //     if (e.isMesh && !e.geometry.attributes.color) {
    //         const ca = new THREE.Float32BufferAttribute(
    //             new Float32Array(
    //                 e.geometry.attributes.position.count * 3
    //             ),
    //             3
    //         );
    //         e.geometry.setAttribute("color", ca);
    //         e.geometry.attributes.color.array.fill(1);

    //     }
    // });
    // ktx2Loader.dispose();
    // return panel.scene;
};

export { loadIcon, initLoaders, loadMaterials, loadOBJPanelModule, loadGLTFModule };
